.container {
  margin: 0 10%;
  padding: 3rem 0;
}

.title {
  margin-bottom: 10px;
}

.heading {
  display: inline-block;
}

.jobs {
  margin-top: 40px;
}
